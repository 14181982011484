import React from "react";
import "./index.scss";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { updateTimestamp } from "../../actions/timestampActions";
import { toggleSiteCreatedCampaign } from "../../actions/siteCreatedCampaignAction";
import { createRandomString } from "../../modules/miscModule";
import { animateBox } from "../../modules/componentAnimation";
import animateModule from "../../modules/animateModule";
import moment from "moment";

import Spinner from "../../components/customComponents/Spinner";
import Button from "../../components/customComponents/Button";
import CustomInput from "../../components/customComponents/CustomInput";
import Dropdown from "../../components/customComponents/Dropdown";
import FilterPanel from "../../components/customComponents/FilterPanel";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";
import { LandingItem } from "../LandingList";

let curTimeout2 = null;
const CampaignsList = () => {
    const [data, setData] = React.useState();
    const [dateFilters, setDateFilters] = React.useState("today");
    const [distinctFilter, setDistinctFilter] = React.useState(false);
    const [filters, setFilters] = React.useState([]);
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);
    const [loadingSpinner, setLoadingSpinner] = React.useState(false);

    const [domains, setDomains] = React.useState([]);
    const [websites, setWebsites] = React.useState([]);

    const mainNavigate = useNavigate();
    const mainRef = React.useRef();
    const searchRef = React.useRef();
    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();

    const curTimestampSelector = useSelector(state => state?.timestamp ?? null);
    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const animateNavigate = to => animateModule(mainNavigate, to, document.querySelector(".component__mainContent"));
    let formatter = Intl.NumberFormat('en', { notation: 'standard' });

    let searchHandler = () => {
        let searchVal = searchRef?.current?.value;
        clearTimeout(curTimeout2);
        curTimeout2 = setTimeout(() => {
            setFilters([{
                or: [
                    { name: "Name", op: "like", value: searchVal },
                    { name: "UniqueURL", op: "like", value: searchVal },
                    { name: "FlowType", op: "like", value: searchVal },
                    { name: "UniqueURL", op: "like", value: searchVal }
                ]
            }]);
        }, 500);
    };

    const parseDateFilter = (cDate) => {
        switch (cDate) {
            case "all":
                return [];
            case "today":
                return [{ name: "track-createdAt", op: "deq", value: moment().endOf("day") }];
            case "yesterday":
                return [{ name: "track-createdAt", op: "deq", value: moment().add(-1, "days").endOf("day") }];
            case "7days":
                return [{ name: "track-createdAt", op: "dgeq", value: moment().add(-7, "days").endOf("day") }];
            case "30days":
                return [{ name: "track-createdAt", op: "dgeq", value: moment().add(-30, "days").endOf("day") }];
            case "60days":
                return [{ name: "track-createdAt", op: "dgeq", value: moment().add(-60, "days").endOf("day") }];
            default: return [];
        };
    };

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        setLoadingSpinner(true);
        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                offset: paginationOffset.current,
                filters: [
                    ...filters,
                    ...parseDateFilter(dateFilters)
                ],
                distinct: distinctFilter,
                orders: [{name: "createdAt", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };

                return setData({ ...res.data, timestamp: Date.now() });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).finally(() => {
            setLoadingSpinner(false);
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                offset: data?.data?.length ?? 0,
                filters: [
                    ...filters,
                    ...parseDateFilter(dateFilters)
                ],
                distinct: distinctFilter,
                orders: [{name: "createdAt", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ], timestamp: Date.now()
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch { };

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}></div>;
    };

    const getDomains = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getAllDomains`,
            data: {
                limit: 9999999,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") return setDomains(res.data.data);
        }).catch(() => null);
    };
    const getWebsites = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsiteNames`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") return setWebsites(res.data.data);
        }).catch(() => null);
    };

    const isToday = (dateString) => {
        const date = moment(dateString);
    
        const today = moment();
    
        return date.isSame(today, 'day');
    }

    React.useEffect(() => {
        getData();
    }, [curTimestampSelector, filters, dateFilters, distinctFilter]);

    React.useEffect(() => {
        if (filters?.length > 0) setFilters([]);
        if (dateFilters !== "today") setDateFilters("today");

    }, [siteSettingsSelector?.advancedSearch]);

    React.useEffect(() => {
        getDomains();
        getWebsites();
    }, []);

    return <div className="route__campaignsList" ref={mainRef} style={{ paddingTop: "10px" }}>
        {!data ? <Spinner align="center" /> : <>
            {data.status === "ok" ? <>
                {siteSettingsSelector?.advancedSearch ?
                    <FilterPanel accent="rgb(63, 124, 234)" theme="dark" filters={[
                        { name: "Name", friendlyName: "Name", type: "string" },
                        {
                            name: "DomainID", friendlyName: "Domain", type: "custom", data: domains.map(d => {
                                return { text: d.Name, value: d.ID }
                            }), varType: "number"
                        },
                        { name: "UniqueURL", friendlyName: "Unique URL", type: "string" },
                        {
                            name: "FlowType", friendlyName: "Flow Type", type: "custom", data: [
                                { text: "Predefined once the user opens the page", value: "predefined" }
                            ], varType: "string"
                        },
                        { name: "track-createdAt", friendlyName: "Date", type: "date" },
                        {
                            name: "PreLanderList", friendlyName: "Pre-landing page", type: "custom", data: websites.filter(t => t.Type === "prelanding").map(w => {
                                return { text: w.Name, value: `w:${w.ID}` }
                            }), varType: "stringIncludes"
                        },
                        {
                            name: "LanderList", friendlyName: "Landing page", type: "custom", data: websites.filter(t => t.Type === "landing").map(w => {
                                return { text: w.Name, value: `w:${w.ID}` }
                            }), varType: "stringIncludes"
                        },
                        {
                            name: "ThankyouList", friendlyName: "Thank you page", type: "custom", data: websites.filter(t => t.Type === "thankyou").map(w => {
                                return { text: w.Name, value: `w:${w.ID}` }
                            }), varType: "stringIncludes"
                        },
                        {
                            name: "BotList", friendlyName: "Bot page", type: "custom", data: websites.filter(t => t.Type === "thankyou").map(w => {
                                return { text: w.Name, value: `w:${w.ID}` }
                            }), varType: "stringIncludes"
                        }
                    ]} filterCB={f => setFilters(f)} /> :
                    <>
                        <CustomInput autocomplete="off" ref={searchRef} onInput={searchHandler} theme="dark" accent="#3F7CEA" placeholder="Search..." />
                        <div className="route__campaignsList__dateFilter">
                            <span className="route__campaignsList__dateFilter__head">Date: </span>
                            <div onClick={() => setDateFilters("all")} className={dateFilters === "all" ? "route__campaignsList__dateFilter__item--active" : ""}><span>All</span></div>
                            <div onClick={() => setDateFilters("today")} className={dateFilters === "today" ? "route__campaignsList__dateFilter__item--active" : ""}><span>Today</span></div>
                            <div onClick={() => setDateFilters("yesterday")} className={dateFilters === "yesterday" ? "route__campaignsList__dateFilter__item--active" : ""}><span>Yesterday</span></div>
                            <div onClick={() => setDateFilters("7days")} className={dateFilters === "7days" ? "route__campaignsList__dateFilter__item--active" : ""}><span>Last 7 days</span></div>
                            <div onClick={() => setDateFilters("30days")} className={dateFilters === "30days" ? "route__campaignsList__dateFilter__item--active" : ""}><span>Last 30 days</span></div>
                            <div onClick={() => setDateFilters("60days")} className={dateFilters === "60days" ? "route__campaignsList__dateFilter__item--active" : ""}><span>Last 60 days</span></div>
                        </div>
                    </>
                }
                <div className="route__campaignsList__dateFilter">
                    <span className="route__campaignsList__dateFilter__head">Only unique: </span>
                    <div onClick={() => setDistinctFilter(true)} className={distinctFilter === true ? "route__campaignsList__dateFilter__item--active route__campaignsList__dateFilter__item--blue" : ""}><span>Yes</span></div>
                    <div onClick={() => setDistinctFilter(false)} className={distinctFilter === false ? "route__campaignsList__dateFilter__item--active route__campaignsList__dateFilter__item--blue" : ""}><span>No</span></div>

                    <Spinner style={{width: "30px", height: "30px", marginLeft: "20px", opacity: loadingSpinner ? 1 : 0, transition: "opacity 0.3s ease"}} color="white" />
                </div>
                {data.data.length > 0 && <div className="route__campaignsList__item__row" style={{ padding: "15px" }}>
                    <p>Campaign name</p>
                    <p>Visits</p>
                    <p>Conversions</p>
                    <p style={{ textAlign: "end" }}>Actions</p>
                </div>}
                {data.data.length === 0 && <p>There is nothing to display.</p>}

                {data.data.map(elem => {
                    return <div className={`route__campaignsList__item route__campaignsList__item--${elem.ID}`} key={`campaign-${elem.ID}`}>
                        <div className="route__campaignsList__item__row">
                            <p>{elem.Name} {elem.FlowType === "rotation" && ` (Rotation)`} {elem.BotsDisallowed && ` (Bots disallowed)`} {elem.RespectCountry && ` (unknown country disallowed)`} {elem.SiteGuard && ` (SiteGuard enabled)`}</p>
                            <p>{formatter.format(elem.clicks)}</p>
                            <p>{formatter.format(elem.conversions)}</p>
                            <div className="route__campaignsList__item__row__actions">
                                <p title="View disallows / blocked users" onClick={e => animateNavigate(`/main/campaign/${elem.ID}/disallows`)}><img src="/images/disallowsIcon.png" /></p>
                                <p title="Get campaign URL" onClick={e => animateBox(e, <CampaignURLGen data={elem} />)}><img src="/images/wwwIcon.png" /></p>
                                {(userSelector?.Flags?.isAdmin || userSelector?.ID === elem.UserID) && <p title="Edit campaign" onClick={e => animateBox(e, <AddCampaign data={elem} />)}><img src="/images/editCampaign.png" /></p>}
                                {(userSelector?.Flags?.isAdmin || userSelector?.ID === elem.UserID) && <p title="Remove campaign" onClick={e => animateBox(e, <RemoveCampaign data={elem} onChange={() => {
                                    let node = mainRef.current?.querySelector(`.route__campaignsList__item--${elem.ID}`);
                                    if (!node) return getData();

                                    node.animate([
                                        { opacity: getComputedStyle(node).opacity },
                                        { opacity: 0 }
                                    ], {
                                        duration: 300,
                                        iterations: 1,
                                        fill: "both",
                                        easing: "ease"
                                    }).onfinish = () => getData();
                                }} />)}><img src="/images/deleteCampaign.png" /></p>}
                            </div>
                        </div>
                        {(elem.BotsDisallowed || elem.RespectCountry || elem.SiteGuard) && elem.BotFinal.length > 0 && <>
                            <div className="route__campaignsList__item__row route__campaignsList__item__row--inset">
                                <p>{[(elem.BotsDisallowed ? "Bot" : null), (elem.RespectCountry ? "Country failover" : null), (elem.SiteGuard ? "Site guard" : null)].filter(t => t).join(" / ")} pages</p>
                                <p>{formatter.format(elem.totalBotClicks)}</p>
                                <p>-</p>
                            </div>
                            {elem.BotFinal.map(elemBot => {
                                return <div className="route__campaignsList__item__row route__campaignsList__item__row--inset route__campaignsList__item__row--inset2">
                                    <p>{elemBot.Name} (ID: {elemBot.ID})</p>
                                    <p>{formatter.format(elemBot.clicks)}</p>
                                    <p>-</p>
                                    <div className="route__campaignsList__item__row__actions">
                                        <p title="View page in new tab" onClick={e => {
                                            e.stopPropagation();
                                            window.open(`${backendModule.backendURL}/websites/preview/${elemBot.ID}`, "_blank");
                                        }}><img src="/images/showWebsiteCampaign.png" /></p>
                                    </div>
                                </div>
                            })}
                        </>}

                        {elem.PreLanderList.length > 0 && <>
                            <div className="route__campaignsList__item__row route__campaignsList__item__row--inset">
                                <p>Pre-landing pages</p>
                                <p>{formatter.format(elem.totalPrelanderClicks)}</p>
                                <p>-</p>
                            </div>
                            {elem.PreLanderFinal.map(elemLander => {
                                return <div className="route__campaignsList__item__row route__campaignsList__item__row--inset route__campaignsList__item__row--inset2">
                                    <p>{elemLander.Name} (ID: {elemLander.ID})</p>
                                    <p>{formatter.format(elemLander.clicks)}</p>
                                    <p>-</p>
                                    <div className="route__campaignsList__item__row__actions">
                                        <p title="View page in new tab" onClick={e => {
                                            e.stopPropagation();
                                            window.open(`${backendModule.backendURL}/websites/preview/${elemLander.ID}`, "_blank");
                                        }}><img src="/images/showWebsiteCampaign.png" /></p>
                                    </div>
                                </div>
                            })}
                        </>}

                        {elem.LanderList.length > 0 && <>
                            <div className="route__campaignsList__item__row route__campaignsList__item__row--inset">
                                <p>Landing pages</p>
                                <p>{formatter.format(elem.totalLanderClicks)}</p>
                                <p>{formatter.format(elem.conversions)}</p>
                            </div>
                            {elem.LanderFinal.map(elemLander => {
                                return <div className="route__campaignsList__item__row route__campaignsList__item__row--inset route__campaignsList__item__row--inset2">
                                    <p>{elemLander.Name} (ID: {elemLander.ID})</p>
                                    <p>{formatter.format(elemLander.clicks)}</p>
                                    <p>{formatter.format(elemLander.conversions)}</p>
                                    <div className="route__campaignsList__item__row__actions">
                                        <p title="View page in new tab" onClick={e => {
                                            e.stopPropagation();
                                            window.open(`${backendModule.backendURL}/websites/preview/${elemLander.ID}`, "_blank");
                                        }}><img src="/images/showWebsiteCampaign.png" /></p>
                                    </div>
                                </div>
                            })}
                        </>}

                        {elem.ThankyouList.length > 0 && <>
                            <div className="route__campaignsList__item__row route__campaignsList__item__row--inset">
                                <p>Thank you pages</p>
                                <p>{formatter.format(elem.totalThankyouClicks)}</p>
                                <p>-</p>
                            </div>
                            {elem.ThankyouFinal.map(elemLander => {
                                return <div className="route__campaignsList__item__row route__campaignsList__item__row--inset route__campaignsList__item__row--inset2">
                                    <p>{elemLander.Name} (ID: {elemLander.ID})</p>
                                    <p>{formatter.format(elemLander.clicks)}</p>
                                    <p>-</p>
                                    <div className="route__campaignsList__item__row__actions">
                                        <p title="View page in new tab" onClick={e => {
                                            e.stopPropagation();
                                            window.open(`${backendModule.backendURL}/websites/preview/${elemLander.ID}`, "_blank");
                                        }}><img src="/images/showWebsiteCampaign.png" /></p>
                                    </div>
                                </div>
                            })}
                        </>}
                        {elem.BackButtonRedirectURL && <>
                            <div className="route__campaignsList__item__row route__campaignsList__item__row--inset">
                                <p>Back button redirect URL</p>
                                <p></p>
                                <p></p>
                            </div>
                            <div className="route__campaignsList__item__row route__campaignsList__item__row--inset route__campaignsList__item__row--inset2">
                                <p style={{gridColumn: "1 / span all"}}>{elem.BackButtonRedirectURL}</p>
                            </div>
                        </>}
                        {isToday(elem.createdAt) ? <img className="badgeImage" src="./images/newBadge.png" /> : null}
                    </div>
                })}

                {secondarySpinner && <Spinner align="center" />}
                {canPaginate && <PaginationData />}
            </> : <p>There was an error while fetching campaigns!</p>}
        </>}
    </div>
};

const CampaignURLGen = props => {
    const [data, setData] = React.useState();
    const [offerInfo, setOfferInfo] = React.useState();
    const [campaignInfo, setCampaignInfo] = React.useState();

    const getURL = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/generateURL`,
            data: {
                ID: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                setData({ status: "error", data: "SERVER_ERROR" });
            };
        }).catch(() => {
            setData({ status: "error", data: "SERVER_ERROR" });
        });
    };

    const getCampaignInfo = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getCampaignByID`,
            data: {
                ID: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getOfferInfo(res.data.data.AdditionalData.ScaleTrack_OfferID)
                setCampaignInfo(res.data);
            } else {
                setCampaignInfo({ status: "error", data: "SERVER_ERROR" });
            };
        }).catch((e) => {
            setCampaignInfo({ status: "error", data: "SERVER_ERROR" });
        });
    }

    const getOfferInfo = (id) => {
        if(!id) return;
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getSingleOfferInfo`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setOfferInfo(res.data);
            } else {
                setOfferInfo({ status: "error", data: "SERVER_ERROR" });
            };
        }).catch(() => {
            setOfferInfo({ status: "error", data: "SERVER_ERROR" });
        });
    }

    const openURL = (url) => {
        try {
            let tmp = new URL(url);
            tmp.searchParams.set("sttempfix", "v1");
            window.open(tmp, "_blank");
        } catch {};
    };

    React.useEffect(() => {
        getURL();
        getCampaignInfo();
    }, []);

    return <div className="route__createWebisteDialog">
        <div className="route__createWebisteDialog__wrap" style={{width: "600px"}}>
            <h3 style={{ marginBottom: "20px" }}>Campaign URL</h3>

            {!data ? <Spinner color="#3F7CEA" align="center" /> : <>
                {data.status === "ok" ? <>
                    <p>URL for <span style={{ color: "#3F7CEA" }}>{props.data.Name}</span>:</p>
                    <p style={{ marginTop: "20px", marginBottom: "20px" }}>{data.data}</p>
                </> : <p>There was an error while generating the URL!</p>}
            </>}

            {!offerInfo ? <Spinner color="#3F7CEA" align="center" /> : <>
                {offerInfo.status === "ok" ? <>
                    <p>Offer Name: <span style={{ color: "#3F7CEA", marginTop: '20px' }}>{offerInfo.data[0].OfferName} ({offerInfo.data[0].Country} {offerInfo.data[0].OfferType})</span></p>
                </> : <p>There was an error while getting Offer name</p>}
            </>}

            <div className="route__createWebisteDialog__wrap__btns">
                <p onClick={() => {
                    navigator.clipboard.writeText(data.data);

                    props.onClose();
                }}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Copy to clipboard</span>
                </p>
                <p onClick={() => {
                    openURL(data.data);

                    props.onClose();
                }}>
                    <img src="/images/showWebsiteCampaign.png" style={{
                        borderRadius: "50%",
                        backgroundColor: "#3f7cea",
                        marginRight: "15px",
                        padding: "6px"
                    }} />
                    <span>Preview</span>
                </p>
                <p onClick={() => {
                    props.onClose();
                }}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>
        </div>
    </div>
};

const ModalError = (props) => {
    return <div className="route__createWebisteDialog">
        <div className="route__createWebisteDialog__wrap">
            <h3 style={{ marginBottom: "10px" }}>{props.heading ? props.heading : "Error"}</h3>
            <p style={{ marginBottom: "20px", whiteSpace: "pre-wrap" }}>{props.error}</p>
            <div className="route__createWebisteDialog__wrap__btns">
                <p onClick={() => {
                    props.onClose().then(() => {
                        if (props.onChange) props.onChange();
                    });
                }}>
                    <img style={{ marginRight: "15px" }} src="/images/closeBtn.png" />
                    <span>Close</span>
                </p>
            </div>
        </div>
    </div>
};

let curTimeout = null;
const SelectWebsite = (props) => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);

    const mainRef = React.useRef();
    const searchRef = React.useRef();

    let appliedFilters = [
        ...filters,
        { name: "Type", op: "eq", value: props.type }
    ]

    if (props.country) {
        appliedFilters.push({ name: "Country", op: "eq", value: props.country });
    }
    // if (props.type === 'landing') {
    //     appliedFilters.push({ name: "Status", op: "eq", value: 100 });
    // }

    const getTemplates = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsites`,
            data: {
                filters: appliedFilters,
                limit: undefined,
                orders: [{name: "createdAt", order: "desc"}],
                fromCampaign: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                setData({ status: "error", data: "SERVER_ERROR" });
            };
        }).catch(() => {
            setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            setSpinner(false);
        });
    };

    const selectWebsite = id => {
        props.onChange(id);
        props.onClose();
    };

    React.useEffect(() => {
        if (!searchRef.current) return;

        let handler = () => {
            let searchVal = searchRef?.current?.value;
            clearTimeout(curTimeout);
            curTimeout = setTimeout(() => {
                setFilters([{ name: "--searcher", value: searchVal }])
            }, 500);
        };

        searchRef.current.addEventListener("input", handler);

        return () => {
            try {
                searchRef.current.removeEventListener("input", handler);
            } catch { };
        };
    }, [searchRef.current]);

    React.useEffect(() => {
        setData();
        getTemplates();
    }, [filters]);

    return <div className="route__openTemplateModal" ref={mainRef}>
        <div className="route__openTemplateModal__wrap" style={{ gridTemplateColumns: "1fr" }}>
            <div className="route__openTemplateModal__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => {
                if (spinner) e.stopPropagation();
            }}>
                <Spinner accent="#3F7CEA" />
            </div>

            <div className="route__openTemplateModal__wrap__heading" style={{ marginBottom: "20px" }}>
                <h3>Select website</h3>
                <div className="route__openTemplateModal__wrap__btns">
                    <p onClick={() => {
                        props.onClose();
                    }}>
                        <span>Close</span>
                        <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                    </p>
                </div>
            </div>

            <CustomInput autocomplete="off" ref={searchRef} accent="#3F7CEA" theme="dark" placeholder="Search..." style={{ gridColumn: "1 / span all" }} />
            <div className="route__openTemplateModal__wrap__templates">
                {!data ? <Spinner /> : <>
                    {data.status === "error" ? <p>There was an error while getting websites!</p> : <>
                        {data.data.length === 0 && <p>No websites found</p>}
                        {data.data.map((elem) => {
                            return <LandingItem
                                key={`${elem.ID}-${elem.updatedAt}`}
                                data={elem}
                                buttons={<>
                                    <Button value="Select template" accent="rgb(63, 124, 234)" style={{ width: "150px" }} onClick={e => {
                                        e.stopPropagation();
                                        selectWebsite(elem);
                                    }} />
                                </>}
                            />
                        })}
                    </>}
                </>}
            </div>

        </div>
    </div>
};

const AddCampaign = props => {
    const [users, setUsers] = React.useState();
    const [domains, setDomains] = React.useState();
    const [allCampaigns, setAllCampaigns] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");
    const [selectedLanders, setSelectedLanders] = React.useState([]);
    const [selectedPrelanders, setSelectedPrelanders] = React.useState([]);
    const [selectedThankyous, setSelectedThankyous] = React.useState([]);
    const [selectedBots, setSelectedBots] = React.useState([]);
    const [selectedDomain, setSelectedDomain] = React.useState();
    const [selectedFlowType, setSelectedFlowType] = React.useState();
    const [siteGuard, setSiteGuard] = React.useState(true);
    const [botsDisallowed, setBotsDisallowed] = React.useState(false);
    const [respectCountry, setRespectCountry] = React.useState(false);
    const [offers, setOffers] = React.useState([]);
    const [selectedOffer, setSelectedOffer]=React.useState();
    const [selectedOfferCountry, setSelectedOfferCountry]=React.useState();
    const [selectedUser, setSelectedUser] = React.useState();
    const [backButtonRedirect, setBackButtonRedirect] = React.useState(null);
    const cancelBtnRef = React.useRef();
    const campaignNameRef = React.useRef();
    const urlPrefixRef = React.useRef();

    const [currentSite, setCurrentSite] = React.useState()

    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const curDispatch = useDispatch();

    const getDomains = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getAllDomains`,
            data: {
                offset: 0,
                limit: 999999
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setDomains(res.data);
            } else {
                animateBox({ currentTarget: cancelBtnRef.current }, <ModalError error={`Error while fetching domains, a server error occured!`} onChange={props.onClose} />);
                setDomains({ status: "error", data: "SERVER_ERROR" });
            };
        }).catch(() => {
            animateBox({ currentTarget: cancelBtnRef.current }, <ModalError error={`Error while fetching domains, server timed out!`} onChange={props.onClose} />);
            setDomains({ status: "error", data: "SERVER_ERROR" });
        });
    };

    const getOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllOffers`,
            data: {
                offset: 0,
                limit: 10000
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setOffers(res.data);
            }
        }).catch(() => {
            setOffers({ status: "error", data: "SERVER_ERROR" });
        });
    };

    const getUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            ...backendModule.axiosConfig
        }).then(res => {
            setUsers(res.data);
        }).catch(() => {
            setUsers({status: "error", data: "SERVER_ERROR"});
        });
    };

    const getAllCampaigns = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                limit: null,
                offset: 0,

                includeStats: false,
                parseSites: false
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllCampaigns(res.data);
        }).catch(() => {
            setAllCampaigns(backendModule.genericError);
        });
    };

    const addCampaign = () => {
        setInfoP("");

        let data = {
            UserID: selectedUser,
            DomainID: selectedDomain,
            UniqueURL: urlPrefixRef.current.value,
            Name: campaignNameRef.current.value,
            PreLanderList: selectedPrelanders.map(sl => sl.ID),
            LanderList: selectedLanders.map(sl => sl.ID),
            ThankyouList: selectedThankyous.map(sl => sl.ID),
            BotList: selectedBots.map(sl => sl.ID),
            FlowType: selectedFlowType,
            SiteGuard: !!siteGuard,
            BotsDisallowed: botsDisallowed,
            RespectCountry: respectCountry,
            Offer: selectedOffer,
            BackButtonRedirectID: backButtonRedirect
        };
        if (props.data) data["ID"] = props.data.ID

        if (!data.Name) return setInfoP("Name can't be empty!");
        if (!data.UniqueURL) return setInfoP("URL prefix can't be empty!");
        if (!data.DomainID) return setInfoP("Domain can't be empty!");
        if (!data.FlowType) return setInfoP("Flow type can't be empty!");
        if (data.LanderList.length === 0) return setInfoP("Campaign should have at least 1 landing page!");
        if (data.ThankyouList.length === 0) return setInfoP("Campaign should have at least 1 Thank you page!");

        let websitesToAssignToCampaign = [...data.LanderList, ...data.ThankyouList, ...data.PreLanderList];

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/${props.data ? "edit" : "add"}Campaign`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                let campaignID = res.data.data
                curDispatch(updateTimestamp());
                curDispatch(toggleSiteCreatedCampaign());
                props.onClose();

                const websitesToEdit = websitesToAssignToCampaign.map(async id => {
                    const data = { ID: id, AssignedToCampaign: campaignID }; // Create the data object for each request
                
                    try {
                        const res = await axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/websites/editWebsitesAssignedCampaign`,
                            data,
                            ...backendModule.axiosConfig
                        });
                    } catch (err) {
                        console.log("error while assigning campaigns to websites ", err);
                    }
                });

                Promise.all(websitesToEdit)
                .then(responses => {
                    responses.forEach(res => {
                        console.log("Response:", res.data);
                    });
                })
                .catch(error => {
                    console.log("Error while assigning campaigns to websites:", error);
                });


                let tmp = document.querySelector(".component__header__right__btn > span");
                if (tmp) {
                    animateBox({currentTarget: tmp}, <CampaignURLGen data={{...data, ID: res.data.data}} />);
                };
            } else {
                if (res.data.data === "UNIQUE_URL_NOT_UNIQUE") {
                    return setInfoP("Unique URL already exists!");
                };
                setInfoP("An error occured!");
            };
        }).catch(() => {
            setInfoP("Server time out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    const prefillData = async() => {

        const staticSiteBot = await axios.post(`${backendModule.backendURL}/websites/getAllWebsites`, {
            filters: [
                { name: "Type", op: "eq", value: 'static' },
                { name: "Name", op: "startsWith", value: 'Bio Plar' }
            ],
            limit: 1,
            orders: [{name: "createdAt", order: "desc"}]
        }, backendModule.axiosConfig);

        if(staticSiteBot.data.status === 'ok' && staticSiteBot.data.data.length){
            setSelectedBots([{ ID: staticSiteBot.data.data[0].ID, Name: staticSiteBot.data.data[0].Name }])
        }


        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0'); 
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); 
        const year = currentDate.getFullYear(); 
        const formattedDate = `${day}/${month}/${year}`;

        campaignNameRef.current.value = ` - ${formattedDate}`


        const initialDomain = await axios.post(`${backendModule.backendURL}/domains/getAllDomains`, {
            filters: [
                { name: "Name", op: "startsWith", value: 'Prirodan' }
            ],
            limit: 1,
            orders: [{name: "createdAt", order: "desc"}]
        }, backendModule.axiosConfig);

        if(initialDomain.data.status === 'ok' && initialDomain.data.data.length){
            setSelectedDomain(initialDomain.data.data[0].ID)
        }


        urlPrefixRef.current.value = createRandomString(10).toLowerCase();
        setSelectedUser(userInfoSelector.ID)


        if(!props.curLocation) return;
        
        let values = props.curLocation.pathname.split("/")
        let currID = values[values.length - 1];

        const res = await axios.post(`${backendModule.backendURL}/websites/getWebsiteByID`, {
            ID: currID
        }, backendModule.axiosConfig);

        if(res.data.status !== "ok") return;

        const thankYouPage = await axios.post(`${backendModule.backendURL}/websites/getAllWebsites`, {
            filters: [
                { name: "Type", op: "eq", value: 'thankyou' },
                { name: "Country", op: "eq", value: `${res.data.data.Country}` },
                { name: "Article", op: "eq", value: `${res.data.data.Article}` },
            ],
            limit: 1,
            orders: [{name: "createdAt", order: "desc"}]
        }, backendModule.axiosConfig);

        setCurrentSite(res.data);
        setSelectedLanders([{ID: res.data.data.ID, Name: `${res.data.data.Name} (${res.data.data.Country}, ${res.data.data.Article})`}])
        
        console.log("OVO JE THANK YOU PAGE ", thankYouPage)
        if(thankYouPage.data.status === 'ok' && thankYouPage.data.data.length){
            setSelectedThankyous([{ ID: thankYouPage.data.data[0].ID, Name: thankYouPage.data.data[0].Name }])
        }

        const currOffers = await axios.post(`${backendModule.backendURL}/campaigns/getAllOffers`, {
            ID: currID
        }, backendModule.axiosConfig);
        let currentOffer = currOffers.data.data.find(el => el.OfferName === res.data.data.Article && el.Country === res.data.data.Country)
        
        if(currOffers.data.status === 'ok'){
            setSelectedOffer(currentOffer.ID)
        }

        campaignNameRef.current.value = `${res.data.data.Article} (${res.data.data.Country}) - ${formattedDate}`
    }

    React.useEffect(() => {
        if (domains?.status !== "ok") return;
        if (!props.data) return;
        campaignNameRef.current.value = props.data.Name;
        urlPrefixRef.current.value = props.data.UniqueURL;
        setSelectedDomain(props.data.DomainID);
        setSelectedFlowType(props.data.FlowType);
        setBotsDisallowed(props.data.BotsDisallowed);
        setRespectCountry(props.data.RespectCountry);
        setSelectedPrelanders(props.data.PreLanderFinal ?? []);
        setSelectedLanders(props.data.LanderFinal ?? []);
        setSelectedThankyous(props.data.ThankyouFinal ?? []);
        setSelectedBots(props.data.BotFinal ?? []);
        setSiteGuard(props.data.SiteGuard);
        setSelectedUser(props.data.UserID);
        setBackButtonRedirect(props.data.BackButtonRedirectID);
    }, [domains]);

    React.useEffect(() => {
        getOffers();
        getDomains();
        getUsers();
        getAllCampaigns();
        if (!props.data) prefillData();
    }, [])


    return <div className="route__campaignsList__addCampaign">
        <div className="route__campaignsList__addCampaign__wrap">
            <div className="route__campaignsList__addCampaign__wrap__spinner" style={{
                opacity: (domains && !spinner) ? 0 : 1,
                pointerEvents: (domains && !spinner) ? "none" : "all"
            }} onClick={(e) => (!domains || spinner) && e.stopPropagation()}>
                <Spinner color="#2c7de3" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>{props.data ? "Edit" : "Add"} campaign</h3>

            <CustomInput autocomplete="off" ref={campaignNameRef} theme="dark" accent="#fff" placeholder="Campaign name" style={{ width: "100%", marginBottom: "20px" }} />
            <div style={{ marginBottom: "20px", display: "grid", alignItems: "center", gridTemplateRows: "1fr", gridTemplateColumns: "1fr auto auto", columnGap: "10px" }}>
                <CustomInput autocomplete="off" ref={urlPrefixRef} theme="dark" accent="#fff" placeholder="Unique URL prefix" style={{ width: "100%" }} />
                <Button theme="dark" accent="#2c7de3" value="Random" style={{ width: "70px", height: "30px" }} onClick={() => {
                    urlPrefixRef.current.value = createRandomString(10).toLowerCase();
                }} />
                <Button theme="dark" accent="#EA913F" value="Help" style={{ width: "70px", height: "30px" }} onClick={(e) => {
                    animateBox(e, <ModalError heading="URL prefix" error={`URL prefix defines from which endpoint can campaign be accessed.\nFor instance: if URL prefix is 'example' and the selected domain is 'test.com',\nFinal URL will be 'example.test.com\nNOTE: URL prefix is unique to a campaign, there can't be more than one of the same prefix!'`} />);
                }} />
            </div>

            {(userInfoSelector?.Flags?.isAdmin && users?.status === "ok") && <Dropdown
                inlinePlaceholder="Responsible person"
                theme="dark"
                accent="#fff"
                data={users.data.map(u => {
                    return {name: u.Username, value: u.ID};
                })}
                onChange={e => e?.value && setSelectedUser(e?.value)}
                selected={(()=>{
                    if (users?.status !== "ok") return null;
                    return users.data.indexOf(users.data.find(u => u.ID === selectedUser));
                })()}
            />}
            {domains && (domains.status === "ok" ? <>
                <Dropdown inlinePlaceholder="Domain" theme="dark" accent="#fff" style={{ marginTop: "20px" }} data={domains.data.map(d => {
                    return { name: `Domain: ${d.Name} (SSL: ${d.isSSL ? "yes" : "no"})`, value: d.ID }
                })} onChange={e => setSelectedDomain(e?.value)} selected={(() => {
                    if (domains?.status !== "ok") return;
                    return domains.data.indexOf(domains.data.find(d => d.ID === selectedDomain));
                })()} />
            </> : <p>Error while loading domains!</p>)}

            {offers && (offers.status === "ok" ? <>
                <Dropdown inlinePlaceholder="Offer" theme="dark" accent="#fff" style={{ marginTop: "20px" }} data={offers.data.map(d => {
                    return { name:`${d.OfferName} (${d.Country ?? "?"}, ${d.OfferType ?? "-"})`, value: d.ID, country: d.Country ?? "?" }
                })} onChange={e => {setSelectedOffer(e?.value); setSelectedOfferCountry(e.country)}} selected={((e) => {
                    if (offers?.status !== "ok") return;
                    return offers.data.indexOf(offers.data.find(d => d.ID === selectedOffer));
                })()} />
            </> : null)}
            <Dropdown inlinePlaceholder="Flow type" theme="dark" accent="#fff" style={{ marginTop: "20px" }} data={[
                { name: "Flow type: Random once the user opens the page", value: "predefined" },
                { name: "Flow type: Rotation - spread site visits evenly", value: "rotation" }
            ]} onChange={e => setSelectedFlowType(e?.value)} selected={(() => {
                switch (selectedFlowType) {
                    case "predefined": return 0;
                    case "rotation": return 1;
                    default: return 0;
                };
            })()} />

            {allCampaigns?.status === "ok" && <Dropdown
                style={{ marginTop: "20px" }}
                placeholder="Back button redirect campaign"
                theme="dark"
                accent="#fff"
                data={[
                    {name: "Disabled", value: null},
                    ...allCampaigns.data.map(c => {
                        return {value: c.ID, search: c.Name, name: <div className="route__campaignsList__addCampaign__wrap__dropdownElem">
                            <Button value="Previews" style={{width: "70px", height: "20px"}} onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                animateBox(e, <CampaignSitePreviews data={c} />);
                            }} accent="rgb(234, 145, 63)" />
                            <span>{c.Name}</span>
                        </div>};
                    })
                ]}
                selected={(()=>{
                    if (!backButtonRedirect) return 0;
                    if (allCampaigns?.status !== "ok") return null;

                    return allCampaigns.data.indexOf(allCampaigns.data.find(ac => ac.ID === backButtonRedirect)) + 1;
                })()}
                onChange={e => setBackButtonRedirect(e?.value)}
            />}

            <CustomCheckbox theme="dark" accent="#3F7CEA" placeholder="Site Guard (external service)" style={{ marginTop: "20px" }} defaultValue={siteGuard} onChange={e => setSiteGuard(e)} />
            <CustomCheckbox theme="dark" accent="#3F7CEA" placeholder="Disallow bots from visiting the site" style={{ marginTop: "20px" }} defaultValue={botsDisallowed} onChange={e => setBotsDisallowed(e)} />
            <CustomCheckbox theme="dark" accent="#3F7CEA" placeholder="Disallow unspecified country (filter by country)" style={{ marginTop: "20px" }} defaultValue={respectCountry} onChange={e => setRespectCountry(e)} />
            <p className="route__campaignsList__addCampaign__wrap__websiteItem" style={{ display: (botsDisallowed || respectCountry || siteGuard) ? null : "none" }}>
                <span>
                    <span style={{ color: "rgb(234, 145, 63)" }}>{[(siteGuard ? "Guard redirect" : null), (botsDisallowed ? "Bot" : null), (respectCountry ? "Country failover" : null)].filter(t => t).join(" / ")}</span>
                    &nbsp;pages (optional)</span>
                <p onClick={(e) => {
                    animateBox(e, <SelectWebsite type="static" onChange={ws => {
                        setSelectedBots(sl => [...sl, { ID: ws.ID, Name: ws.Name }]);
                    }} />)
                }}>
                    <img style={{ marginRight: "15px" }} src="/images/addBtn.png" />
                    <span>Add page</span>
                </p>
            </p>
            <p className="route__campaignsList__addCampaign__wrap__websiteList" style={{ display: (botsDisallowed || respectCountry || siteGuard) ? null : "none" }}>
                {selectedBots.map((ws, wsIdx) => {
                    return <p>
                        <img src="/images/trashCan.png" onClick={() => setSelectedBots(sl => sl.filter((_, slIdx) => slIdx !== wsIdx))} />
                        <span>{ws.Name}</span>
                    </p>
                })}
            </p>

            <p className="route__campaignsList__addCampaign__wrap__websiteItem">
                <span>Pre-landing pages</span>
                <p onClick={(e) => {
                    animateBox(e, <SelectWebsite type="prelanding" onChange={ws => {
                        setSelectedPrelanders(sl => [...sl, { ID: ws.ID, Name: `${ws.Name} (${ws.Country}, ${ws.Article})` }]);
                    }} />)
                }}>
                    <img style={{ marginRight: "15px" }} src="/images/addBtn.png" />
                    <span>Add page</span>
                </p>
            </p>
            <p className="route__campaignsList__addCampaign__wrap__websiteList">
                {selectedPrelanders.map((ws, wsIdx) => {
                    return <p>
                        <img src="/images/trashCan.png" onClick={() => setSelectedPrelanders(sl => sl.filter((_, slIdx) => slIdx !== wsIdx))} />
                        <span>{ws.Name}</span>
                    </p>
                })}
            </p>

            <p className="route__campaignsList__addCampaign__wrap__websiteItem">
                <span>Landing pages</span>
                <p onClick={(e) => {
                    animateBox(e, <SelectWebsite type="landing" country={selectedOfferCountry ? selectedOfferCountry : ''} onChange={ws => {
                        setSelectedLanders(sl => [...sl, { ID: ws.ID, Name: `${ws.Name} (${ws.Country}, ${ws.Article})` }]);
                    }} />)
                }}>
                    <img style={{ marginRight: "15px" }} src="/images/addBtn.png" />
                    <span>Add page</span>
                </p>
            </p>
            <p className="route__campaignsList__addCampaign__wrap__websiteList">
                {selectedLanders.map((ws, wsIdx) => {
                    return <p>
                        <img src="/images/trashCan.png" onClick={() => setSelectedLanders(sl => sl.filter((_, slIdx) => slIdx !== wsIdx))} />
                        <span>{ws.Name}</span>
                    </p>
                })}
            </p>

            <p className="route__campaignsList__addCampaign__wrap__websiteItem">
                <span>Thank you pages</span>
                <p onClick={(e) => {
                    animateBox(e, <SelectWebsite type="thankyou" onChange={ws => {
                        setSelectedThankyous(sl => [...sl, { ID: ws.ID, Name: `${ws.Name} (${ws.Country}, ${ws.Article})` }]);
                    }} />)
                }}>
                    <img style={{ marginRight: "15px" }} src="/images/addBtn.png" />
                    <span>Add page</span>
                </p>
            </p>
            <p className="route__campaignsList__addCampaign__wrap__websiteList">
                {selectedThankyous.map((ws, wsIdx) => {
                    return <p>
                        <img src="/images/trashCan.png" onClick={() => setSelectedThankyous(sl => sl.filter((_, slIdx) => slIdx !== wsIdx))} />
                        <span>{ws.Name}</span>
                    </p>
                })}
            </p>

            <div className="route__campaignsList__addCampaign__wrap__btns">
                <p onClick={addCampaign}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose} ref={cancelBtnRef}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p style={{ color: "#f48e8e", marginTop: "20px" }}>{infoP}</p>}
        </div>
    </div>
};

const CampaignSitePreviews = props => {
    const [data, setData] = React.useState();
    const [websites, setWebsites] = React.useState();

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                limit: 1,
                offset: 0,
                filters: [
                    {name: "ID", op: "eq", value: props.data.ID}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getWebsites(res.data.data);
                setData({status: "ok", data: res.data.data[0]});
            } else {
                setData(backendModule.genericError);
            };
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const getWebsites = campaigns => {
        let ids = [];
        for (let c of campaigns) {
            ids.push(...c.BotList);
            ids.push(...c.LanderList);
            ids.push(...c.PreLanderList);
            ids.push(...c.ThankyouList);
        };
        ids = [...new Set(ids)];
        if (ids.length === 0) return setWebsites(backendModule.genericError);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsites`,
            data: {
                limit: null,
                offset: 0,
                filters: [
                    {name: "ID", op: "in", value: ids}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setWebsites(res.data);
        }).catch(() => {
            setWebsites(backendModule.backendURL);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="route__campaignsList__addCampaign" onClick={e => e.stopPropagation()}>
        <div className="route__campaignsList__addCampaign__wrap">
            <h3 style={{marginBottom: "20px"}}>Site previews</h3>

            {(data && websites) ? <>
                {(data.status === "ok" && websites.status === "ok") ? <>
                    {data.data.BotList.length > 0 && <>
                        <p>Bot pages</p>
                        {data.data.BotList.map(l => {
                            let curLander = websites.data.find(w => w.ID === l);
                            if (!curLander) return null;
                            return <div className="route__campaignsList__addCampaign__wrap__website">
                                <Button value="View" style={{width: "70px", height: "30px"}} onClick={() => window.open(`${backendModule.backendURL}/websites/preview/${curLander.ID}`, "_blank")} />
                                <p>{curLander.Name} ({curLander.Country}, {curLander.Article}) (ID: {curLander.ID})</p>
                            </div>;
                        })}
                    </>}
                    {data.data.PreLanderList.length > 0 && <>
                        <p>Pre-landing pages</p>
                        {data.data.PreLanderList.map(l => {
                            let curLander = websites.data.find(w => w.ID === l);
                            if (!curLander) return null;
                            return <div className="route__campaignsList__addCampaign__wrap__website">
                                <Button value="View" style={{width: "70px", height: "30px"}} onClick={() => window.open(`${backendModule.backendURL}/websites/preview/${curLander.ID}`, "_blank")} />
                                <p>{curLander.Name} ({curLander.Country}, {curLander.Article}) (ID: {curLander.ID})</p>
                            </div>;
                        })}
                    </>}
                    {data.data.LanderList.length > 0 && <>
                        <p>Landing pages</p>
                        {data.data.LanderList.map(l => {
                            let curLander = websites.data.find(w => w.ID === l);
                            if (!curLander) return null;
                            return <div className="route__campaignsList__addCampaign__wrap__website">
                                <Button value="View" style={{width: "70px", height: "30px"}} onClick={() => window.open(`${backendModule.backendURL}/websites/preview/${curLander.ID}`, "_blank")} />
                                <p>{curLander.Name} ({curLander.Country}, {curLander.Article}) (ID: {curLander.ID})</p>
                            </div>;
                        })}
                    </>}
                    {data.data.ThankyouList.length > 0 && <>
                        <p>Thank you pages</p>
                        {data.data.ThankyouList.map(l => {
                            let curLander = websites.data.find(w => w.ID === l);
                            if (!curLander) return null;
                            return <div className="route__campaignsList__addCampaign__wrap__website">
                                <Button value="View" style={{width: "70px", height: "30px"}} onClick={() => window.open(`${backendModule.backendURL}/websites/preview/${curLander.ID}`, "_blank")} />
                                <p>{curLander.Name} ({curLander.Country}, {curLander.Article}) (ID: {curLander.ID})</p>
                            </div>;
                        })}
                    </>}
                </> : <p>There was an error while fetching data!</p>}
            </> : <>
                <p>Fetching campaign data...</p>
                <Spinner />
            </>}

            <div className="route__campaignsList__addCampaign__wrap__btns">
                <p></p>
                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>
        </div>
    </div>
};

const RemoveCampaign = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const removeCampaign = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/removeCampaign`,
            data: {
                ID: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose().then(() => {
                    if (props.onChange) props.onChange();
                });
            } else {
                setInfoP("There was an error while removing the domain!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__domainsList__addDomain">
        <div className="route__domainsList__addDomain__wrap">
            <div className="route__domainsList__addDomain__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>

            <h3>Remove campaign</h3>
            <p>Are you sure? You are about to delete <span style={{ color: "#3F7CEA" }}>{props.data.Name}</span></p>
            <p>This action cannot be undone</p>

            <div className="route__domainsList__addDomain__wrap__btns">
                <p onClick={removeCampaign}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Remove</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__domainsList__addDomain__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

export default CampaignsList;
export { AddCampaign };